input[type="file"] {
  position: absolute;
  z-index: -1;
  top: 15px;
  left: 20px;
  font-size: 17px;
  color: #b8b8b8;
}
.button-wrap {
  position: relative;
}
.button {
  display: inline-block;
  background-color: #397cff;
  border-radius: 10px;
  border: 4px double #cccccc;
  color: #ffffff;
  text-align: center;
  font-size: 12px;
  font-weight: normal;
  padding: 8px;
  /* transition: all 0.5s; */
  cursor: pointer;
  margin: 5px;
  /* width: 260px; */
  width: 100%;
}
.button:hover {
  background-color: #0341b8;
}